<template>
  <div class="analyze_warp">
    <div class="left">
      <div class="title">
        学习数据
      </div>
      <div class="content">
        <div class="item">
          <div>{{info.paper_count}}</div>
          <div>总提交试卷</div>
        </div>

        <div class="item">
          <div>
            {{info.paper_detail_count}}
          </div>
          <div>完成题目</div>
        </div>

        <div class="item">
          <div>
            {{info.paper_point}}
          </div>
          <div>总得分率(%)</div>
        </div>

        <div class="item">
          <div>
            {{info.score_change}}
          </div>
          <div>得分率变化(%)</div>
        </div>
      </div>
    </div>
    <div class="right">
      <!--  -->
      <div @click="showMessage('/knowledge/line')">
        <span class="text">
          <span>
            考点曲线
          </span>
          <span>
            考点得分率变化情况
          </span>
        </span>
        <span class="img">
          <img src="@/static/analyze/zhishidian.png"
               alt="">
        </span>
      </div>
      <!--  -->
      <div @click="showMessage('/studentAnalysis')">
        <span class="text">
          <span>
            学情分析
          </span>
          <span>
            查看各科弱点情况
          </span>
        </span>
        <span class="img">
          <img src="@/static/analyze/xueqing.png"
               alt=""></span>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref } from 'vue'
import { instance } from '@/utils/http.js'
import { useRouter } from 'vue-router'
let info = ref({})
let router = useRouter()
const getInfo = async () => {
  const { data } = await instance({
    url: '/api/v1/user/user_report',
    method: 'get'
  })
  info.value = data
}
getInfo()
const showMessage = (path) => {
  // if (this.timeOutId) {
  //   return
  // }
  // this.timeOutId = setTimeout(() => {
  //   this.timeOutId = 0
  // }, 1500)
  // this.$message.warning('暂无数据')

  router.push(path)
}

</script>

<style lang="scss" scoped>
.analyze_warp {
  display: flex;
  .left {
    overflow: hidden;
    position: relative;
    width: 583rem;
    height: 824rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../static/analyze/study1.png");
    margin: 65rem 101rem 0 162rem;
    .title {
      text-align: center;
      margin-top: 377rem;
      font-size: 36rem;
      font-weight: 800;
      color: #b05c27;
    }
    .content {
      position: absolute;
      bottom: 10px;
      width: 543rem;
      height: 340rem;
      left: 10px;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          &:nth-child(1) {
            font-size: 50rem;
            font-weight: 800;
            color: #b65d25;
          }
          &:nth-child(2) {
            font-size: 28rem;
            font-weight: 500;
            color: #a98873;
          }
        }
      }
    }
  }
  .right {
    margin-top: 160rem;
    div {
      width: 749rem;
      height: 320rem;
      box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
      border-radius: 60rem;
      border: 20rem solid #fac376;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      cursor: pointer;
      .text {
        flex: 1;
        margin-left: 80rem;
        span {
          display: block;
          &:nth-child(1) {
            font-size: 40rem;
            font-weight: 800;
            color: #000000;
          }
          &:nth-child(2) {
            font-size: 28rem;
            font-weight: bold;
            color: #666666;
            margin-top: 20rem;
          }
        }
      }
      .img {
        margin-right: 90rem;
        img {
          width: 160rem;
          height: 160rem;
        }
      }
      &:nth-child(2) {
        margin-top: 90rem;
      }
    }
  }
}
</style>